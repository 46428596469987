import React from 'react';

const Card = ({creditObj}) => {
  return (
    <div
      className="card card-extra border-0 mx-auto my-3 grow bg-light"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-delay="100"
      data-aos-duration="800"
      data-aos-once="false"
    >
      <div className="card-header border-0">{creditObj.name}</div>
      <div className="card-body">
        <h4 className="card-title">{creditObj.role}</h4>
        <p className="card-text">{creditObj.theater + '/' + creditObj.personnel}</p>
      </div>
    </div>
  );
};

export default Card;
