import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50 column">
      <div className="container">
        Copyright &copy; Riley J. Ewing
      </div>
      <div>
        Built by <a href='https://shanelonergan.dev/'>Shane Lonergan</a>
      </div>
    </footer>
  );
}
