import React from 'react';
import Card from '../components/Card';
import MasterClass from '../components/MasterClass';
import resume from '../resume-info';
import resumePDF from '../assets/images/riley-j-ewing-resume.pdf';

export default function Resume() {
  const renderCredits = resumeObj => {
    const cards = resumeObj.stage.map(credit => {
      return <Card key={credit.name} creditObj={credit} />;
    });
    return cards;
  };

  const renderMasterclasses = resumeObj => {
    const masterClassArr = resumeObj.masterclasses.map(masterClassStr => {
      return (
        <MasterClass key={masterClassStr} masterClassStr={masterClassStr} />
      );
    });
    return masterClassArr;
  };

  return (
    <>
      <div className="container">
        <div
          className="text-center"
          data-aos="slide-up"
          data-aos-duration="1000"
          data-aos-delay="50"
          data-aos-once="false"
        >
          <h1 className=" text-primary">EXPERIENCE</h1>
        </div>
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          {renderCredits(resume)}
        </div>
      </div>

      <div className="container">
        <div
          className="text-center"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-once="false"
        >
          <h1 className=" text-primary">TRAINING</h1>
        </div>
        <div
          className="text-center"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-once="false"
        >
          <h3 className="text-primary">
            BM IN MUSIC THEATER AT BALDWIN WALLACE
          </h3>
        </div>
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-lg">
            <div
              className="card card-extra border-0 mx-auto my-3 grow bg-light w-auto"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="false"
            >
              <div className="card-header border-0">VOCAL INSTRUCTION</div>
              <div className="card-body">
                <h4 className="card-title">J.R. FRALICK</h4>
                <h4 className="card-title">GREG HARRELL</h4>
              </div>
            </div>
            <div
              className="card card-extra border-0 mx-auto my-5 grow bg-light w-auto"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="false"
            >
              <div className="card-header border-0">ACTING INSTRUCTION</div>
              <div className="card-body">
                <h4 className="card-title">WILLIAM ESPER STUDIO (CURRENT)</h4>
                <h4 className="card-title">VICTORIA BUSSERT</h4>
                <h4 className="card-title">SCOTT PLATE</h4>
              </div>
            </div>
            <div
              className="card card-extra border-0 mx-auto my-5 grow bg-light w-auto"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="false"
            >
              <div className="card-header border-0">DANCE INSTRUCTION</div>
              <div className="card-body">
                <h4 className="card-title">GREGORY DANIELS</h4>
                <h4 className="card-title">SARA WHALE</h4>
                <h4 className="card-title">HEIDI GLYNIAS</h4>
              </div>
              <div className="card-footer border-0 bg-light">
                Ballet, Modern, Tap, Hip-Hop & Jazz – 4 years
              </div>
            </div>
          </div>
          <div id="masterclass" className="col my-3">
            <div
              className="text-center"
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="false"
            >
              <h3>
                <u>MASTERCLASSES</u>
              </h3>
            </div>
            {renderMasterclasses(resume)}
          </div>
        </div>
        <div className="row align-items-center">
          <button className="btn btn-primary mx-auto">
            <a className="text-white" href={resumePDF} download>
              Download Resume
            </a>
          </button>
        </div>
      </div>
    </>
  );
}
