import React from 'react'
import config from '../../config';
import Scroll from '../components/Scroll';

export default function Masthead() {
    return (
        <header className="masthead">
        <div className="container d-flex h-100 align-items-center mx-auto">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0 text-uppercase animated slideInLeft">
              {config.heading}
            </h1>
            <div className="row row-cols-2 mx-auto">
              {/* <div className="row"> */}
              <h2 className="text-white-50 mx-auto animated fadeInRightBig delay-1">
                ACTOR
              </h2>
              <h2 className="text-white-50 mx-auto animated fadeInRightBig delay-2">
                SINGER
              </h2>
              {/* </div> */}
              {/* <div className="row"> */}
              <h2 className="text-white-50 mx-auto animated fadeInRightBig delay-3">
                WRITER
              </h2>
              <h2 className="text-white-50 mx-auto animated fadeInRightBig delay-4">
                FILMMAKER
              </h2>
              {/* </div> */}
            </div>
            <Scroll type="id" element="reel">
              <a
                href="#reel"
                className="btn icon animated fadeInUpBig mx-5"
              >
                <i className="fas fa-angle-double-down"></i>
              </a>
            </Scroll>
          </div>
        </div>
      </header>
    )
}
