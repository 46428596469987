import React, { useState, useEffect } from 'react';
import Scroll from './Scroll';

export default function MobileNavbar() {
  const [displayMobileNav, setDisplayMobileNav] = useState('hide');

  const handleScroll = () => {
    if (window.pageYOffset > 600) {
      if (displayMobileNav !== 'show') {
        setDisplayMobileNav('show');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`mobile-navbar ${displayMobileNav}`}

    >
      <ul className="mobile-navbar-nav">
        <li className="mobile-nav-item">
          <Scroll element="reel" type="id">
            <a href="#reel" className="mobile-nav-link">
              <span className="mobile-link-text">Reel</span>
            </a>
          </Scroll>
        </li>

        <li className="mobile-nav-item">
          <Scroll element="about" type="id">
            <a href="#about" className="mobile-nav-link">
              <span className="mobile-link-text">About</span>
            </a>
          </Scroll>
        </li>

        <li className="mobile-nav-item">
          <Scroll element="projects" type="id">
            <a href="#projects" className="mobile-nav-link">
              <span className="mobile-link-text">Experience</span>
            </a>
          </Scroll>
        </li>

        <li className="mobile-nav-item">
          <Scroll element="gallery" type="id">
            <a href="#gallery" className="mobile-nav-link">
              <span className="mobile-link-text">Gallery</span>
            </a>
          </Scroll>
        </li>

        <li className="mobile-nav-item">
          <Scroll element="signup" type="id">
            <a href="#signup" className="mobile-nav-link">
              <span className="mobile-link-text">Contact</span>
            </a>
          </Scroll>
        </li>
      </ul>
    </nav>
  );
}
