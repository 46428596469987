import React from 'react';

export default function MasterClass({masterClassStr}) {
  return (
    <div
      className="card card-extra border-0 mx-auto grow-3 bg-light w-auto"
      data-aos="fade-up"
      data-aos-offset="100"
      data-aos-delay="100"
      data-aos-duration="800"
      data-aos-once="false"
    >
      <div className="card-body text-center">
        <h4 className="card-title">{masterClassStr.toUpperCase()}</h4>
      </div>
    </div>
  );
}
