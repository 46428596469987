import React from 'react'
import riley177 from '../assets/images/Riley-177.jpg';
import riley101 from '../assets/images/Riley-101.jpg';
import riley105 from '../assets/images/Riley-105.jpg';
import riley108 from '../assets/images/Riley-108.jpg';
import riley125 from '../assets/images/Riley-125.jpg';
import rileyOnSet from '../assets/images/riley-on-set.jpg';

export default function Gallery() {
    return (
        <div className="container">
          {/* <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop --> */}
          <div className="row">
            <div className="col-md-4 p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src="https://i.imgur.com/0OJHES7.jpg"
                  className="img-fill-1 b-and-w"
                  // width="400"
                  // height="250"
                />
              </div>
            </div>
            <div className="col-md-4 p-0 vh-50">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="100"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src={rileyOnSet}
                  className="img-fill-1 b-and-w"
                />
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="300"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src={riley125}
                  className="img-fill-1 b-and-w"
                  width="400"
                  height="250"
                />
              </div>
            </div>
          </div>
          {/* second image row */}
          <div className="row">
            <div className="col p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="150"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src={riley101}
                  className="img-fill-2 b-and-w"
                  // width="250"
                  // height="500"
                />
              </div>
            </div>
            <div className="col-6 p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="500"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src="https://i.imgur.com/vyY7yp1.jpg"
                  className="img-fill-3 b-and-w"
                  // width="600"
                  // height="500"
                />
              </div>
            </div>
            <div className="col p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="400"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src={riley105}
                  className="img-fill-2 b-and-w"
                  // width="250"
                  // height="500"
                />
              </div>
            </div>
          </div>

          {/* <!-- Columns are always 50% wide, on mobile and desktop --> */}
          <div className="row">
            <div className="col-md p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src={riley177}
                  className="img-fill-4 b-and-w"
                  // width="300"
                  // height="500"
                />
              </div>
            </div>
            <div className="col-md p-0">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="100"
                data-aos-duration="800"
                data-aos-once="false">
                <img
                  src="https://i.imgur.com/KrX64C0.jpg"
                  className="img-fill-4 b-and-w"
                  // width="570"
                  // height="500"
                />
              </div>
            </div>
          </div>
        </div>
    )
}
