import React from 'react'
import mainIMG from '../assets/images/Riley-179.jpg';

export default function About() {
    return (
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2
                className="text-white mb-4"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="100"
                data-aos-duration="800"
                data-aos-once="false"
              >
                I was a twelve year old boy in Kansas City when I figured it
                out.
              </h2>
              <p
                className="text-white-50"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="100"
                data-aos-duration="800"
                data-aos-once="false"
              >
                I've spent my whole life in the arts. Growing up in Overland
                Park, Kansas, I was hungry to learn and grow as an artist. From
                a young age, I've trained in acting, singing, & dancing. Now I
                live in Brooklyn where I spend my days chasing after the same
                dream I've had since childhood. As a performer, Riley has worked
                in theatres across the country, on tour, and off-broadway. He
                runs his own production company, <a href='https://www.facebook.com/SpaceheadProductions/' target='_blank'>SPACEHEAD Productions</a>, which
                has produced three of Riley’s original short films. His film
                “Hurry Up & Wait” has screened at film festivals across the
                country. His most recent film, “Unplugged” is completed and
                working its way through the festival circuit now. Riley holds a
                Bachelor’s in Music from the Baldwin Wallace Conservatory of
                Music, has completed the core curriculum training at the Upright
                Citizens Brigade Training Center and is currently enrolled in
                acting training courses at the William Esper Studio. Riley’s
                passion is to tell new and innovative stories is only just
                beginning.
              </p>
            </div>
          </div>
          <img src={mainIMG} className="img-fluid" alt="" />
        </div>
    )
}
