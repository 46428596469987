import React, { useState } from 'react';
import * as emailjs from 'emailjs-com';

export default function Subscribe() {
  emailjs.init(process.env.REACT_APP_EMAIL_USER_ID);

  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleSubmit = e => {
    e.preventDefault();

    const { name, email, subject, message } = contactForm;

    let templateParams = {
      from_name: name,
      reply_to: email,
      to_name: 'sptlonergan@gmail.com',
      subject: subject,
      message_html: message,
    };

    emailjs.send(
      'gmail',
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAIL_USER_ID
    )
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

    resetForm();
  };

  const resetForm = () => {
    setContactForm({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
  };

  const handleChange = event => {
    setContactForm({ ...contactForm, [event.target.name]: event.target.value });
  };

  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">Get in touch!</h2>

            <form className="form-group" onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                value={contactForm.email}
                onChange={handleChange}
                className="form-control form-control-lg my-3"
                id="inputEmail"
                placeholder="Enter your email"
              />
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={contactForm.name}
                onChange={handleChange}
                className="form-control form-control-lg my-3"
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={contactForm.subject}
                onChange={handleChange}
                className="form-control form-control-lg my-3"
              />
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Message"
                  value={contactForm.message}
                  onChange={handleChange}
                  className="form-control form-control-lg my-3"
                ></textarea>
              </div>
              <button
                type="submit"
                value="submit"
                className="btn btn-primary mx-auto"
              >
                Contact
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
