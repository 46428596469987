import React from 'react'

export default function Reel() {
    return (
        <div
          className="w-75 mx-auto"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-once="false"
        >
          <div className="embed-responsive embed-responsive-16by9 p-3">
            <iframe
              src="https://player.vimeo.com/video/406400127"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        </div>
    )
}
