import React, { useEffect } from 'react';

import Layout from '../components/Layout';
import Gallery from '../components/Gallery'

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
import Reel from '../components/Reel';
import Masthead from '../components/Masthead';
import About from '../components/About';
import Resume from '../components/Resume';
import MobileNavbar from '../components/MobileNavbar';

import AOS from 'aos';
import 'aos/dist/aos.css';

const IndexPage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Layout>
      <MobileNavbar />
      <Header />
      <Masthead />

      <section id="reel">
        <Reel />
      </section>

      <section id="about" className="about-section text-center">
        <About />
      </section>

      <section id="projects" className="projects-section bg-light">
        <Resume />
      </section>

      <section id="gallery" className="gallery-section">
        <Gallery />
      </section>

      <Subscribe />
      <SocialLinks />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
