const credits = {
  stage: [
    {
      name: 'Friends: The Parody Musical',
      role: 'Ross',
      theater: "St. Luke's Theatre",
      personnel: '(Assoc.Dir) Dana Ianuzzi',
    },
    {
      name: 'We The People',
      role: 'Washington',
      theater: 'TheaterWorks USA',
      personnel: 'Matt Dickson',
    },
    {
      name: 'American Idiot',
      role: 'Will',
      theater: 'Beck Center For The Arts',
      personnel: 'Scott Spence',
    },
    {
      name: 'Billy Elliot',
      role: 'Tony',
      theater: 'Beck Center For The Arts',
      personnel: 'Scott Spence',
    },
    {
      name: 'Heathers',
      role: 'Ram',
      theater: 'Beck Center For The Arts',
      personnel: 'Scott Spence',
    },
     {
      name: 'My Fair Lady',
      role: 'Alfred P. Doolittle (U/S)',
      theater: "Great Lakes Theatre",
      personnel: 'Victoria Bussert',
    },
    {
      name: 'West Side Story',
      role: 'Action',
      theater: "Baldwin Wallace",
      personnel: 'Victoria Bussert',
    },
     {
      name: 'Falsettoland',
      role: 'Whizzer',
      theater: "Baldwin Wallace",
      personnel: 'Victoria Bussert',
    },
    {
      name: 'The Fantasticks',
      role: 'Matt',
      theater: 'MidAmerica Nazarene University',
      personnel: 'Jay Coombes',
    },
  ],
  film: [
    {
        name: 'Unplugged',
        role: 'STAR, Writer, Director, Producer',
        personnel: 'SPACEHEAD Productions'
    },
    {
        name: 'Bohemia',
        role: 'PLACEHOLDER',
        personnel: 'Dir. Charlie Smith'
    },
    {
        name: 'Hurry Up & Wait',
        role: 'Writer, Director, Producer',
        personnel: 'SPACEHEAD Productions'
    },
  ],
  masterclasses: [
      'Jed Abrahams',
      'Hunter Bell',
      'Don Birge',
      'David Cash',
      'Michael Cassara',
      'Kevin Chamberlin',
      'Bob Cline',
      'Richard Fischer',
      'Barret Foa',
      'Justin Huff'
  ]
};

export default credits
